






































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IconPeople from '@/app/ui/assets/menu_icon_user_management.vue'
import IconCourier from '@/app/ui/assets/icon_motorcycle_front.vue'
import IconPhone from '@/app/ui/assets/icon_phone.vue'
import IconMap from '@/app/ui/assets/menu_icon_pickup_location.vue'
import IconCancelTask from '@/app/ui/assets/icon_cancel_task.vue'
import IconClock from '@/app/ui/assets/clock2.vue'
import IconWeight from '@/app/ui/assets/ics_o_weight.vue'
import IconBookmark from '@/app/ui/assets/ics_o_bookmark.vue'
import IconProfile from '@/app/ui/assets/ics_f_profile.vue';
import IconPhoneBlack from '@/app/ui/assets/ics_f_phone.vue';
import IconBox from '@/app/ui/assets/ics_f_box_alt.vue';
import IconLocationPin from '@/app/ui/assets/ics_f_location_pin.vue'
import IconCalendar from '@/app/ui/assets/ics_f_calendar_black.vue'
import IconClock2 from '@/app/ui/assets/ics_f_clock2.vue'
import IconNotebook from '@/app/ui/assets/ics_f_notebook.vue'
import ModalSuccess from '@/app/ui/views/Pickup/components/Modals/ModalSuccess.vue'
import ModalConfirmation from '@/app/ui/views/Pickup/components/Modals/ModalConfirmation.vue'
import controller, {
  DropdownValue,
} from '@/app/ui/controllers/PickupController'
import { PickupDetail, PickupHistory, PickupInfo } from '@/domain/entities/Pickup'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import PriorityIcon from '@/app/ui/assets/ics_f_priority-fast.vue'

dayjs.extend(dayjsDuration)

interface Form {
  bookingIds: string
  courier: DropdownValue | undefined
  manualAssignDate?: Date
  isScheduleManualAssign?: boolean
  vehiclePlate?: string
}

interface IPickupDetails {
  shipmentId: string
  customerName: string
  phoneNumber: string
  address: string
  latitude: number
  longitude: number
  lastCancelReason: string
  lastCancelCourier: string
  shipmentGroupsIds: string[]
  weight?: number
  productType?: string[]
  pickup?: PickupInfo
  histories?: PickupHistory[]
  flag?: string
}

@Component({
  components: {
    LoadingOverlay,
    Button,
    IconPeople,
    IconCourier,
    IconPhone,
    IconMap,
    IconCancelTask,
    IconClock,
    IconBookmark,
    IconWeight,
    IconCalendar,
    IconClock2,
    IconPhoneBlack,
    IconProfile,
    IconBox,
    IconLocationPin,
    IconNotebook,
    ModalSuccess,
    ModalConfirmation,
    Badge,
    PriorityIcon
  },
})
export default class PickupManualAssigneeBulkOverviewPage extends Vue {
  controller = controller
  modalConfirmationVisible = false
  modalSuccessVisible = false
  scrollEnd = false
  refetch = false
  groupIds: Array<string> = []
  utils = Utils

  form: Form = {
    bookingIds: '',
    courier: undefined,
    manualAssignDate: undefined,
    isScheduleManualAssign: false,
    vehiclePlate: ''
  }

  pickupDetailsData: Array<IPickupDetails> = []

  created(): void {
    window.addEventListener('scroll', this.handleScroll)

    if (controller.pickupBulkData) {
      this.form = {
        bookingIds: controller.pickupBulkData.bookingIds,
        courier: controller.pickupBulkData.courier,
        manualAssignDate: controller.pickupBulkData.manualAssignDate,
        isScheduleManualAssign:
          controller.pickupBulkData.isScheduleManualAssign,
        vehiclePlate: controller.pickupBulkData.plateNo
      }
    }

    if (this.isCorporate && !this.form.vehiclePlate) {
      this.onBack()
    }

    this.fetchPickupDetailBulk()
  }

  get shipmentIds(): Array<string> {
    return this.$route.params.shipmentId.split(';')
  }

  get courierName(): string {
    if (!this.form.courier) {
      return ''
    }

    const label = this.form.courier.label
    return label.substring(label.indexOf(']') + 1, label.indexOf('(')).trim()
  }

  get statusId(): string {
    return this.$route.meta.additional.statusId
  }

  get previousRoute(): string {
    return this.$route.name?.replace('ManualAssignee', '') || ''
  }

  get shipmentGroupIds(): string[] {
    return this.pickupDetailsData
      .map(pickup =>
        pickup.shipmentGroupsIds.length
          ? [...pickup.shipmentGroupsIds]
          : [pickup.shipmentId]
      )
      .flat(1)
  }

  get manualScheduleAssignTime(): string {
    return this.form.isScheduleManualAssign
      ? Utils.formatTimeZone(
          Utils.formatDateWithIDLocale(
            new Date(this.form.manualAssignDate as Date).toISOString(),
            'DD MMMM YYYY HH:mm Z'
          )
        )
      : '-'
  }

  get chargeableWeight(): string[] {
    return this.pickupDetailsData.map(pickup => {
      return pickup.weight !== undefined
        ? `${Number((pickup.weight / 1000).toFixed(1))} kg`
        : '-'
    })
  }

  get productTypes(): string[][] {
    return <string[][]>this.pickupDetailsData.map(pickup => {
      return pickup.productType?.map(type => {
        if (type === 'SAMEDAY') {
          return 'same day'
        }

        return type.toLowerCase()
      }) || []
    })
  }

  get isCorporate(): boolean {
    return this.$route.name === "PickupCorporateManualAssigneeBulkOverview"
  }


  public corporateCRRNFDAt(item: PickupDetail): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(<string>item.histories?.find((pickup) => {
        return pickup.statusId === "CRRNFD"
      })?.createdAt, 'DD MMMM YYYY, HH:mm Z')
    )
  }

  public corporateCRRNFDDuration(item: PickupDetail): string {
    const createdAt = <string>item.histories?.find((pickup) => {
      return pickup.statusId === "CRRNFD"
    })?.createdAt

    const duration = dayjs.duration(dayjs().diff(dayjs(createdAt)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  private courierSearch = Utils.debounce((search: string) => {
    this.fetchCourierList(search)
  }, 500)

  private fetchPickupDetailBulk(): void {
    const payload = {
      shipment_id: this.shipmentIds.map(item => item.trim()).join(','),
    }

    controller.getPickupDetails(payload)
  }

  private fetchCourierList(search?: string): void {
    if (search === '') search = undefined
    controller.getCourierList({
      perPage: 30,
      q: search,
      courierStatus: 'ACTIVE',
    })
  }

  private onBack(): void {
    if (this.isCorporate) {
      this.$router.push({
        name: "PickupCorporateManualAssignee",
      })
    } else {
      this.$router.push({
        name: `Pickup${this.statusId}ManualAssignee`,
      })
    }
  }

  private onClickMaps(lat: string, long: string): void {
    window.open(`https://maps.google.com?q=${lat},${long}`, '_blank')?.focus()
  }

  private onCloseModalSuccess(): void {
    this.modalSuccessVisible = false
    controller.setPickupBulkData(null)

    if (this.isCorporate) {
      this.$router.push({
        name: 'PickupCorporate',
        query: {
          refetch: this.refetch ? 'true' : undefined,
        },
      })

    } else {
      this.$router.push({
        name: `Pickup${this.statusId}`,
        query: {
          refetch: this.refetch ? 'true' : undefined,
        },
      })
    }
  }

  private handleScroll(): void {
    if (document.body.offsetHeight - window.innerHeight === window.scrollY) {
      this.scrollEnd = true
    } else {
      this.scrollEnd = false
    }
  }

  private submitManualAssignee(): void {
    this.modalConfirmationVisible = false

    const payload: {
      shipmentIds: string[]
      courierId: number
      statusId: string
      manualAssignDate?: string
      timeZone?: string,
      plateNo?: string
    } = {
      shipmentIds: [
        ...new Set(this.form.bookingIds.split(';').filter(item => !!item)),
      ],
      courierId: Number(this.form.courier?.value),
      statusId: this.$route.meta.additional.statusId,
      plateNo: this.form.vehiclePlate,
    }

    if (this.form.isScheduleManualAssign) {
      payload.manualAssignDate = Utils.formatDateWithIDLocale(
        new Date(this.form.manualAssignDate as Date).toISOString(),
        'YYYY-MM-DD HH:mm:ss'
      )
      payload.timeZone = Utils.formatDateWithIDLocale(
        new Date().toISOString(),
        'Z'
      )
    }

    if (!this.isCorporate) {
      delete payload.plateNo
    }

    controller.manualAssignee(payload)
  }

  private getCourierName(label: string): string {
    return label.substring(label.indexOf(']') + 1, label.indexOf('('))
  }

  public formatNFDStatus(value: string | number): string {
    const status = parseInt(<string>value)
    if (status === 1) return 'one'
    if (status === 2) return 'two'
    if (status === 3) return 'three'
    if (status === 4) return 'four'
    if (status === 5) return 'five'
    return 'more'
  }

  public getOverSLA(histories: PickupHistory[]): boolean {
    const createdAt = <string>histories?.find((pickup) => {
      return pickup.statusId === "CRRNFD"
    })?.createdAt
    return dayjs().diff(dayjs(createdAt), 'hours') >= 48
  }

  @Watch('controller.pickupDetails')
  setPickupDetails(data: PickupDetail[]): void {
    let res: Array<IPickupDetails> = []

    data.forEach(item => {
      let lastReason = ''

      const lastCancelData = item.histories?.find(
        history => history.statusId === 'CRRCNC'
      )

      if (lastCancelData) {
        lastReason = lastCancelData.reason
      }

      if (item.groupId && !this.groupIds.includes(item.groupId)) {
        this.groupIds.push(item.groupId)
      }

      res.push({
        ...item,
        shipmentId: item.shipmentId || '',
        customerName: item.pickup?.fullname || '',
        phoneNumber: item.pickup?.phoneNumber || '',
        address: item.pickup?.address || '',
        latitude: item.pickup?.latitude || NaN,
        longitude: item.pickup?.longitude || NaN,
        lastCancelReason: lastReason,
        lastCancelCourier: item.lastCancelCourier || '',
        shipmentGroupsIds: item.shipmentGroupIds || [],
        productType: item.productType,
        weight: item.weight,
      })
    })

    this.pickupDetailsData = res
  }

  convertCurrencyRp(amount: number) {
    return `Rp${Utils.currencyDigit(amount)}`
  }

  @Watch('controller.requestManualAssign')
  onManualAssignBulkError(msg: string): void {
    if (msg === EventBusConstants.MANUAL_ASSIGNEE_SUCCESS) {
      this.modalSuccessVisible = true
    }

    controller.setRequestManualAssign('')
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
