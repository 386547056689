





































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller, {
  DropdownValue,
} from '@/app/ui/controllers/PickupController'
import mcController from '@/app/ui/controllers/ManageCourierController'
import { PickupDetail, PickupHistory } from '@/domain/entities/Pickup'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import IconPeople from '@/app/ui/assets/menu_icon_user_management.vue'
import IconCourier from '@/app/ui/assets/icon_motorcycle_front.vue'
import IconPhone from '@/app/ui/assets/icon_phone.vue'
import IconMap from '@/app/ui/assets/menu_icon_pickup_location.vue'
import IconCancelTask from '@/app/ui/assets/icon_cancel_task.vue'
import IconWeight from '@/app/ui/assets/ics_o_weight.vue'
import IconBookmark from '@/app/ui/assets/ics_o_bookmark.vue'
import ModalSuccess from '@/app/ui/views/Pickup/components/Modals/ModalSuccess.vue'
import ModalConfirmation from '@/app/ui/views/Pickup/components/Modals/ModalConfirmation.vue'
import CustomInputMultiTags from '../components/CustomInputMultitags/index.vue'
import { ManageCourierDetail } from '@/domain/entities/ManageCourier'
import ScheduleAssigneCourier from '../components/ScheduleAssigneCourier/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import { requiredIf } from 'vuelidate/lib/validators'
import IconProfile from '@/app/ui/assets/ics_f_profile.vue'
import IconPhoneBlack from '@/app/ui/assets/ics_f_phone.vue'
import IconBox from '@/app/ui/assets/ics_f_box_alt.vue'
import IconLocationPin from '@/app/ui/assets/ics_f_location_pin.vue'
import IconCalendar from '@/app/ui/assets/ics_f_calendar_black.vue'
import IconClock from '@/app/ui/assets/ics_f_clock2.vue'
import IconNotebook from '@/app/ui/assets/ics_f_notebook.vue'
import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import PriorityIcon from '@/app/ui/assets/ics_f_priority-fast.vue'

dayjs.extend(dayjsDuration)

interface Form {
  bookingIds: string
  courier: DropdownValue | undefined
  manualAssignDate: Date | undefined
  manualAssignHour?: number | undefined
  manualAssignMinute?: number | undefined
  vehiclePlate?: string
}

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    TextInput,
    Button,
    DropdownSelect,
    IconPeople,
    IconCourier,
    IconPhone,
    IconMap,
    IconCancelTask,
    IconBookmark,
    IconWeight,
    ModalSuccess,
    ModalConfirmation,
    CustomInputMultiTags,
    ScheduleAssigneCourier,
    Badge,
    IconProfile,
    IconPhoneBlack,
    IconBox,
    IconLocationPin,
    IconCalendar,
    IconClock,
    IconNotebook,
    PriorityIcon
  },
})
export default class PickupManualAssigneePage extends Vue {
  controller = controller
  mcController = mcController
  modalConfirmationVisible = false
  modalSuccessVisible = false
  refetch = false
  isErrorMaxTags = false
  maxTags = 30
  isScheduleManualAssign = false
  utils = Utils

  form: Form = {
    bookingIds: '',
    courier: undefined,
    manualAssignDate: undefined,
    manualAssignHour: 0,
    manualAssignMinute: 0,
    vehiclePlate: '',
  }

  latestCancel: Record<string, string> = {
    courier: '',
    reason: '',
  }

  created(): void {
    if (!this.isCRRSRC) {
      controller.getPickupDetail(this.$route.params.shipmentId)
      controller.setPickupBulkData(null)
      this.form.bookingIds = this.$route.params.shipmentId
    }

    if (controller.pickupBulkData?.bookingIds) {
      this.form = {
        bookingIds: controller.pickupBulkData.bookingIds,
        courier: controller.pickupBulkData.courier,
        manualAssignDate: controller.pickupBulkData.manualAssignDate,
        manualAssignHour: controller.pickupBulkData.manualAssignHour,
        manualAssignMinute: controller.pickupBulkData.manualAssignMinute,
        vehiclePlate: controller.pickupBulkData.plateNo,
      }
      this.isScheduleManualAssign = Boolean(
        controller.pickupBulkData.isScheduleManualAssign
      )
    }

    this.form.manualAssignDate?.setHours(
      <number>this.form.manualAssignHour,
      <number>this.form.manualAssignMinute
    )

    controller.setPickupBulkData(null)
  }

  get scheduleManualAssignValue(): Date | null {
    return this.form.manualAssignDate
      ? new Date(
          this.form.manualAssignDate.setHours(
            <number>this.form.manualAssignHour,
            <number>this.form.manualAssignMinute
          )
        )
      : null
  }

  get bookingIds(): Array<string> {
    if (this.form.bookingIds) {
      return this.form.bookingIds.split(';')
    }
    return []
  }

  get previousRoute(): string {
    return this.$route.name?.replace('ManualAssignee', '') || ''
  }

  get isCRRSRC(): boolean {
    return !this.$route.params.shipmentId
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get isCourierLoading(): boolean {
    return controller.isCourierLoading
  }

  get pickupDetail(): PickupDetail {
    return controller.pickupDetail
  }

  get courierOptions(): DropdownValue[] {
    return controller.courierData
  }

  get statusId(): string {
    return this.$route.meta.additional.statusId
  }

  get isCorporate(): boolean {
    return this.$route.name === 'PickupCorporateManualAssignee'
  }

  get shipmentIds(): string {
    if (
      this.pickupDetail.shipmentGroupIds &&
      this.pickupDetail.shipmentGroupIds.length > 0
    ) {
      return this.pickupDetail.shipmentGroupIds.join('; ')
    }
    return this.pickupDetail.shipmentId || '-'
  }

  get disableButtonNext(): boolean {
    return (
      !this.form.bookingIds ||
      !this.form.courier ||
      this.$v.$invalid ||
      this.isErrorMaxTags ||
      (this.isScheduleManualAssign && !this.form.manualAssignDate)
    )
  }

  get disableButtonSubmit(): boolean {
    return (
      !this.form.courier ||
      !this.form.bookingIds ||
      this.$v.$invalid ||
      (this.isScheduleManualAssign && !this.form.manualAssignDate)
    )
  }

  get chargeableWeight(): string {
    return this.pickupDetail.weight !== undefined
      ? `${Number((this.pickupDetail.weight / 1000).toFixed(1))} kg`
      : '-'
  }

  get productTypes(): string[] {
    return <string[]>this.pickupDetail.productType?.map(type => {
      if (type === 'SAMEDAY') {
        return 'same day'
      }

      return type.toLocaleLowerCase()
    })
  }

  get corporateCRRNFDAt(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        <string>this.pickupDetail.histories?.find(pickup => {
          return pickup.statusId === 'CRRNFD'
        })?.createdAt,
        'DD MMMM YYYY, HH:mm Z'
      )
    )
  }

  get corporateCRRNFDDuration(): string {
    const createdAt = <string>this.pickupDetail.histories?.find(pickup => {
      return pickup.statusId === 'CRRNFD'
    })?.createdAt

    const duration = dayjs.duration(dayjs().diff(dayjs(createdAt)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  @Validations()
  validations() {
    return {
      form: {
        vehiclePlate: {
          required: requiredIf(() => {
            return this.isCorporate
          }),
          isValidPlateNo: (value: string) => {
            if (!this.isCorporate) return true
            return Utils.checkIndonesiaVehiclePlate(value)
          },
        },
      },
    }
  }

  private fetchCourierDetail(id: string): void {
    mcController.getCourierDetail({ id: id })
  }

  private courierSearch = Utils.debounce((search: string) => {
    this.fetchCourierList(search)
  }, 500)

  private fetchCourierList(search?: string) {
    if (search === '') search = undefined
    controller.getCourierList({
      perPage: 30,
      q: search,
      courierStatus: 'ACTIVE',
    })
  }

  private submitManualAssignee() {
    this.modalConfirmationVisible = false

    const payload: {
      shipmentIds: string[]
      courierId: number
      statusId: string
      manualAssignDate?: string
      timeZone?: string
      plateNo?: string
    } = {
      shipmentIds: [
        ...new Set(this.form.bookingIds.split(';').filter(item => !!item)),
      ],
      courierId: Number(this.form.courier?.value),
      statusId: this.$route.meta.additional.statusId,
      plateNo: this.form.vehiclePlate,
    }

    if (this.isScheduleManualAssign) {
      payload.manualAssignDate = Utils.formatDateWithIDLocale(
        (this.form.manualAssignDate as Date).toISOString(),
        'YYYY-MM-DD HH:mm:ss'
      )
      payload.timeZone = Utils.formatDateWithIDLocale(
        new Date().toISOString(),
        'Z'
      )
    }

    if (!this.isCorporate) {
      delete payload.plateNo
    }

    controller.manualAssignee(payload)
  }

  private onClickMaps(lat: string, long: string): void {
    window.open(`https://maps.google.com?q=${lat},${long}`, '_blank')?.focus()
  }

  private onCloseModalSuccess(): void {
    this.modalSuccessVisible = false

    this.$router.push({
      name: this.previousRoute,
      query: {
        refetch: this.refetch ? 'true' : undefined,
      },
    })
  }

  private onUpdateMultiTags(val: Array<string>): void {
    this.form.bookingIds = val.map(item => item.trim().toUpperCase()).join(';')
  }

  private onClickNext(): void {
    if (this.form.bookingIds && this.form.courier) {
      controller.setPickupBulkData({
        bookingIds: this.form.bookingIds,
        courier: this.form.courier,
        manualAssignDate: this.form.manualAssignDate,
        manualAssignHour: this.form.manualAssignHour,
        manualAssignMinute: this.form.manualAssignMinute,
        isScheduleManualAssign: this.isScheduleManualAssign,
        plateNo: this.form.vehiclePlate,
      })
    }

    if (this.isCorporate) {
      this.$router.push({
        name: 'PickupCorporateManualAssigneeBulkOverview',
        params: {
          shipmentId: this.form.bookingIds,
        },
      })
    } else {
      this.$router.push({
        name: `Pickup${this.statusId}ManualAssigneeBulkOverview`,
        params: {
          shipmentId: this.form.bookingIds,
        },
      })
    }
  }

  private onErrorMultiTags(value: boolean) {
    this.isErrorMaxTags = value
  }

  private onChangeManualAssignDate(value: Date): void {
    if (value) {
      this.form.manualAssignDate = value
      this.form.manualAssignHour = value.getHours()
      this.form.manualAssignMinute = value.getMinutes()

      controller.setPickupBulkData({
        bookingIds: <string>controller.pickupBulkData?.bookingIds,
        courier: <DropdownValue>controller.pickupBulkData?.courier,
        manualAssignDate: this.form.manualAssignDate,
        manualAssignHour: this.form.manualAssignHour,
        manualAssignMinute: this.form.manualAssignMinute,
        isScheduleManualAssign: this.isScheduleManualAssign,
        plateNo: this.form.vehiclePlate,
      })
    } else {
      this.form = {
        ...this.form,
        manualAssignDate: value,
        manualAssignHour: 0,
        manualAssignMinute: 0,
      }

      controller.setPickupBulkData({
        bookingIds: <string>controller.pickupBulkData?.bookingIds,
        courier: <DropdownValue>controller.pickupBulkData?.courier,
        manualAssignDate: this.form.manualAssignDate,
        manualAssignHour: this.form.manualAssignHour,
        manualAssignMinute: this.form.manualAssignMinute,
        plateNo: this.form.vehiclePlate,
      })
    }
  }

  private onToggleScheduleManualAssign(value: boolean): void {
    this.isScheduleManualAssign = value
  }

  public formatNFDStatus(value: string | number): string {
    const status = parseInt(<string>value)
    if (status === 1) return 'one'
    if (status === 2) return 'two'
    if (status === 3) return 'three'
    if (status === 4) return 'four'
    if (status === 5) return 'five'
    return 'more'
  }

  public getOverSLA(): boolean {
    const createdAt = <string>this.pickupDetail.histories?.find(pickup => {
      return pickup.statusId === 'CRRNFD'
    })?.createdAt
    return dayjs().diff(dayjs(createdAt), 'hours') >= 48
  }

  convertCurrencyRp(amount: number) {
    return `Rp${Utils.currencyDigit(amount)}`
  }

  @Watch('controller.pickupDetail')
  onSetLatestCancelReason(data: PickupDetail): void {
    const lastCancelData = this.pickupDetail.histories?.find(
      history => history.statusId === 'CRRCNC'
    )

    if (lastCancelData) {
      this.latestCancel.reason = lastCancelData.reason
      this.fetchCourierDetail(String(lastCancelData.actorId))
    }
  }

  @Watch('mcController.manageCourierDetail')
  onSetLatestCancelCourier(data: ManageCourierDetail): void {
    if (data) {
      this.latestCancel.courier = `[${data.courierId}] ${data.fullName} (${data.announcementCourierType}) ${data.phoneNumber} • ${data.partnerName}`
    }
  }

  @Watch('controller.requestManualAssign')
  onManualAssignBulkError(msg: string): void {
    if (msg === EventBusConstants.MANUAL_ASSIGNEE_SUCCESS) {
      this.modalSuccessVisible = true
    }

    controller.setRequestManualAssign('')
  }

  @Watch('controller.isLoading')
  onLoadingManualAssign(value: boolean): void {
    if (this.form.manualAssignDate) {
      this.form.manualAssignDate = new Date(
        <number>(
          this.form.manualAssignDate?.setHours(
            <number>this.form.manualAssignHour,
            this.form.manualAssignMinute
          )
        )
      )
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.MANUAL_ASSIGNEE_SUCCESS)
  }
}
